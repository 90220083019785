"use client";

import { Controller, FormProvider, useForm } from "react-hook-form";
import styles from "./consulting.module.scss";
import Image from "next/image";
import { CheckButton } from "../input/CheckInput";
import { useEffect, useState } from "react";
import { useCreateEventLog, useEventUtils } from "@/hook/useEvent";
import useUserStore from "@/store/user";

interface Props {
  setIsConsulting: React.Dispatch<React.SetStateAction<boolean>>;
  nonImage?: boolean;
}

export default function Consulting({ setIsConsulting, nonImage }: Props) {
  const user = useUserStore((state) => state.user);
  const { setValue, getValues } = useForm();
  const formMethods = useForm();

  const { mutate: createLog } = useCreateEventLog();
  const { encryptUserInfo } = useEventUtils();

  const [userTel, setUserTel] = useState([
    user ? user.tel.slice(0, 3) : "",
    user ? user.tel.slice(3, 7) : "",
    user ? user.tel.slice(7, 11) : "",
  ]);
  const [agreeAll, setAgreeAll] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeAd, setAgreeAd] = useState(false);

  const handleFormSubmit = async (formData: any) => {
    setValue("userName", formData.userName);
    setValue("userTel", userTel[0] + userTel[1] + userTel[2]);
    setValue("agreeAd", agreeAd);

    if (!formData.userName) {
      alert("이름을 입력하세요.");
      return;
    }

    if (!userTel[0] || !userTel[1] || !userTel[2]) {
      alert("전화번호를 입력하세요.");
      return;
    }

    if (!agreePrivacy) {
      alert("개인정보 이용에 동의해주시기 바랍니다.");
      return;
    }

    createLog(
      {
        eventCode: "240111",
        key: "상담신청 (pc)",
        memberIdx: user?.idx || -1,
        memberId: user?.id || "",
        userInfo: encryptUserInfo(JSON.stringify({ name: getValues("userName"), tel: getValues("userTel") })),
        value: JSON.stringify({
          이벤트할인광고성동의: getValues("agreeAd"),
        }),
      },
      {
        onSuccess: () => {
          alert("상담신청이 완료되었습니다.");
          setIsConsulting(false);
        },
        onError: (err) => {
          alert("잠시 후에 다시 시도해주세요.");
          console.error(err);
        },
      }
    );

    return;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={styles.consultingWrap}>
      <div className={styles.bg} onClick={() => setIsConsulting(false)}></div>
      <div className={styles.consultingInner}>
        {!nonImage && (
          <>
            <button type="button" className={styles.closeBtn} onClick={() => setIsConsulting(false)}>
              닫기
            </button>
            <Image
              src={`${process.env.NEXT_PUBLIC_S3_IMG_URL}/event/consulting/consulting_title.jpg`}
              alt="상담신청"
              width={520}
              height={467}
            />
          </>
        )}
        <FormProvider {...formMethods}>
          <form className={styles.consultingBox} onSubmit={formMethods.handleSubmit((data) => handleFormSubmit(data))}>
            {nonImage && (
              <button className={`${styles.nonImageBtn}`} onClick={() => setIsConsulting(false)}>
                &#215;
              </button>
            )}
            <p className={styles.borderBottom}>감정평가사 전문상담진의 1:1 학습상담</p>
            <div className={styles.userInfoBox}>
              <Controller
                control={formMethods.control}
                name="userName"
                defaultValue={user ? user?.name : ""}
                render={({ field }) => (
                  <div className={styles.userInfo}>
                    <label htmlFor={field.name}>이름</label>
                    <input
                      type="text"
                      id={field.name}
                      name={field.name}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || ""}
                      placeholder="이름을 입력해주세요"
                    />
                  </div>
                )}
              />
              <div className={styles.userInfo}>
                <label htmlFor={"userTel"}>연락처</label>
                <div className={styles.userTelBox}>
                  <input
                    type="tel"
                    name={"userTel"}
                    id={`userTel01`}
                    maxLength={3}
                    onChange={(e) => {
                      if (/^[0-9]*$/.test(e.target.value)) {
                        setUserTel([e.target.value, userTel[1], userTel[2]]);
                      }
                    }}
                    value={userTel[0]}
                  />
                  <span>-</span>
                  <input
                    type="tel"
                    name={"userTel"}
                    id={`userTel02`}
                    maxLength={4}
                    onChange={(e) => {
                      if (/^[0-9]*$/.test(e.target.value)) {
                        setUserTel([userTel[0], e.target.value, userTel[2]]);
                      }
                    }}
                    value={userTel[1]}
                  />
                  <span>-</span>
                  <input
                    type="tel"
                    name={"userTel"}
                    id={`userTel03`}
                    maxLength={4}
                    onChange={(e) => {
                      if (/^[0-9]*$/.test(e.target.value)) {
                        setUserTel([userTel[0], userTel[1], e.target.value]);
                      }
                    }}
                    value={userTel[2]}
                  />
                </div>
              </div>
            </div>
            <p>개인정보 수집/이용 동의</p>
            <div className={styles.agreeBox}>
              <div className={styles.agreeInner}>
                1. 개인정보 수집·이용 목적
                <br />
                1) 상담신청에 따른 안내 및 관련 문의 사항 응대 등
                <br />
                2) 이벤트 참여 내역 관리를 통한 혜택 중복 수령 방지 등
                <br />
                <strong>
                  3) 광고성 정보 수신에 별도 동의한 자에 한하여 해커스 감정평가사를 비롯한 해커스 교육그룹의 새로운
                  서비스 신상품이나 이벤트, 최신 정보 안내 등 신청자의 취향에 맞는 최적의 서비스를 제공하기 위함.
                </strong>
                <br />
                (해커스교육그룹: 해커스인강, 해커스프랩, 해커스톡, 해커스중국어, 해커스일본어, 해커스잡, 해커스금융,
                해커스임용, 해커스공무원, 해커스경찰, 해커스소방, 해커스어학원, 해커스주택관리사, 해커스공인중개사,
                위더스교육, 위더스독학사, 해커스편입 등)
                <br />
                <br />
                2. 개인정보 수집·이용 항목: 이름, 휴대폰번호
                <br />
                <br />
                <strong>
                  3. 개인정보 보유/이용 기간: 수집한 개인정보는 수집 시로부터 1년간 이용하며,내역관리를 위해 추가 1년을
                  보관한 후 파기합니다. (총 2년 보유) 다만, 보유 및 이용기간 동안 개인정보 이용에 철회하시는 경우에는 그
                  즉시 파기합니다.
                </strong>
                <br />
                <br />
                4. 신청자는 개인정보 수집·이용을 거부할 수 있습니다. 단, 거부의 경우에는 상담신청이 제한됩니다.
              </div>
            </div>

            <div className={styles.checkbox}>
              <CheckButton
                types="checkbox"
                value="Y"
                theme="default"
                id="consultingAgree01"
                name="consultingAgree"
                label="모두 동의합니다."
                changed={() => {
                  setAgreeAll((prev) => {
                    setAgreePrivacy(!prev);
                    setAgreeAd(!prev);
                    return !prev;
                  });
                }}
                isSelected={agreeAll}
              />
              <CheckButton
                types="checkbox"
                value=""
                theme="default"
                id="consultingAgree02"
                name="consultingAgree"
                label="개인정보 이용에 동의합니다. (필수)"
                changed={() => {
                  setAgreePrivacy((prev) => {
                    setAgreeAll(!prev && agreeAd);
                    return !prev;
                  });
                }}
                isSelected={agreePrivacy}
              />

              <CheckButton
                types="checkbox"
                value=""
                theme="default"
                id="consultingAgree03"
                name="consultingAgree"
                label="이벤트/할인(광고성) 정보 안내에 동의합니다. (선택)"
                changed={() => {
                  setAgreeAd((prev) => {
                    setAgreeAll(!prev && agreePrivacy);
                    return !prev;
                  });
                }}
                isSelected={agreeAd}
              />
            </div>

            <button type="submit" className={styles.submitBtn}>
              무료 상담신청 &gt;
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
