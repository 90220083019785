import { getDomain } from "@/assets/ts/Utils";
import React, { useEffect } from "react";

export const LoginCk = (
  router: any,
  status: any,
  urls?: string,
  redirect?: string,
) => {
  if (status === "unauthenticated") {
    alert("로그인 후 이용가능합니다.");

    if (redirect) {
      router.push(
        `https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/login?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${redirect}`,
      );
    } else {
      router.push(
        `https://${process.env.NEXT_PUBLIC_ENV || ""}member.hackers.com/login?service_id=${process.env.NEXT_PUBLIC_SERVICE_ID}&return_url=${encodeURIComponent(
          getDomain(),
        )}`,
      );
    }

    return false;
  }

  if (urls) {
    router.push(urls);
  }
};
